import React, { useEffect, useRef, useState } from 'react'

import GlobalApi from '../Services/GlobalApi'
import { Link } from "react-router-dom";

import CompActive from '../Componentes/CompActive'

import { MdChevronLeft, MdChevronRight } from "react-icons/md";


const fotourl = 'https://api.judah.tv/uploads'

const tamanhotela = window.innerWidth;


function Slider() {

    const barref = useRef();

    const [trailerList, settrailerList] = useState([])

    const [isloading, setisloading] = useState(true);

    useEffect(
        () => {
          _carregatela()
        }, [])
    
    
      async function _carregatela() {
    
    
    
        const resposta1 = await GlobalApi.getTrailer
        if (resposta1) {
            settrailerList(resposta1.data)
        }
   
        
    
    
    
    
        setisloading(false)
    
      }



   

    const btesquerda = (barra) => {

        barra.scrollLeft -= tamanhotela - 110
    }
    const btdireita = (barra) => {

        barra.scrollLeft += tamanhotela - 110
    }

    return (

        <div>


{(isloading) ? (<>
        <div style={{
          width: '4rem',
          height: '4rem',
          margin: '0 auto',
          padding: '.4rem',
        }}>
         {/* <CompActive /> */}
        </div>
      </>) : (<>


            <MdChevronLeft className='hidden md:block  text-black text-[32px] absolute mx-8 mt-[200px] cursor-pointer z-40' onClick={() => { btesquerda(barref.current) }} />

            <MdChevronRight className='hidden md:block text-black text-[32px] absolute mx-8 mt-[200px] cursor-pointer right-0 z-40' onClick={() => { btdireita(barref.current) }} />

            <div className='flex overflow-x-auto w-full px-16 scrollbar-hide scroll-smooth z-0 ' ref={barref}>
                {trailerList.map((item, index) => (

                    <Link to={`/Canal/${item.linktoken}`} className='min-w-full h-[240px] md:h-[440px] mr-5 relative z-0 ' >
 
                            <img key={index} src={`${fotourl}/${item.fototv}.jpg`} alt="Descrição da imagem" class="min-w-full h-[240px] md:h-[440px] object-cover object-center mr-5 rounded-md hover:border-[4px] border-indigo-950 transition-all duration-100 ease-in z-0 " />
                            <div className="absolute bottom-2 left-2  text-white  font-bold text-1xl md:text-4xl px-3 py-1 rounded z-10">
                                {item.username}
                            </div> 


                    </Link>

                ))}

            </div>

            </>)}


        </div>

    )
}

export default Slider
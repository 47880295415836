import React, { useState, useContext, useEffect } from 'react';
import logo from './../assets/logomeupastor.png';
import person from './../assets/person.png';
import { BiSolidBible } from "react-icons/bi";
import { Link } from "react-router-dom";
import { MdTv, MdChurch, MdPerson, MdMusicNote, MdOutlineSearch } from "react-icons/md";
import HeaderItem from './HeaderItem';
import { TbDotsVertical, TbUserEdit } from "react-icons/tb";
import { BsCalendarDay } from "react-icons/bs";
import { FaTheaterMasks } from "react-icons/fa";
import { FaMusic } from "react-icons/fa6";
import { MdFestival } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import './Header.css';
import { MeuContexto } from "../App";

function Header() {
  const [toggle, settoggle] = useState(false);
  const { username, logado, setlogado } = useContext(MeuContexto);

  const menu = [
    { name: 'Início', icon: IoMdHome, link: '/' },
    { name: 'Procurar', icon: MdOutlineSearch, link: '/Lives/Procurar' },
    { name: 'Agenda', icon: BsCalendarDay, link: '/Agenda' },
    { name: 'Igrejas', icon: MdChurch, link: '/Lives/Igrejas' },
    { name: 'Pastores', icon: BiSolidBible, link: '/Lives/Pastores' },
    { name: 'Shows', icon: MdTv, link: '/Lives/Shows' },
  ];

  useEffect(() => {
    console.log(logado, setlogado, username);
  }, []);

  function _sair() {
    setlogado(false);
  }

  return (
    <div className='flex items-center justify-between p-4'>
      <div className='flex gap-8 items-center'>
        <Link to="/" className='min-w-[120px] md:min-w-[168px] xl:min-w-[240px]' onClick={() => { window.location.href = "/"; }} >
          <img src={logo} alt="Logo" className='w-[120px] md:w-[168px] xl:w-[240px] object-cover m-2' />
        </Link>
        <div className='hidden lg:flex gap-4'>
          {menu.map((item, index) => (
            <Link key={index} to={item.link} onClick={() => { window.location.href = item.link; }} >
              <HeaderItem name={item.name} Icon={item.icon} />
            </Link>
          ))}
        </div>
        <div className='flex gap-3 lg:hidden'>
          {menu.slice(0, 3).map((item, index) => (
            <Link key={index} to={item.link} onClick={() => { window.location.href = item.link; }} >
              <HeaderItem name='' Icon={item.icon} />
            </Link>
          ))}
          <div className='lg:hidden' onClick={() => settoggle(!toggle)}>
            <HeaderItem name='' Icon={TbDotsVertical} />
            {toggle && (
              <div className='absolute mt-3 bg-white p-3 px-5 py-3 z-50'>
                {menu.slice(3).map((item, index) => (
                  <Link key={index + 3} to={item.link} onClick={() => { window.location.href = item.link; }} >
                    <HeaderItem name={item.name} Icon={item.icon} />
                  </Link>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        {(!logado) ? (
          <div className='text-right'>
            <div className='text-indigo-950 text-[12px] md:text-[16px] mb-1'>
              <Link to="/SignIn">ENTRAR</Link>
            </div>
            <div className='text-black text-[12px] md:text-[16px]'>
              <Link to="/SignUp">Cadastrar</Link>
            </div>
          </div>
        ) : (
          <div className='text-right'>
            <div className='text-indigo-950 text-[16px] mb-1'>
              <Link to="/Usuario">{username}</Link>
            </div>
            <div className='text-black text-[16px]'>
              <button onClick={() => { _sair() }}>Sair</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;

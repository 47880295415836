import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from "react-router-dom"

import './PgSignUp.css';


import { MeuContexto } from "../App"
import { global } from '../DataAPI/global'
import { data_user } from '../DataAPI/datauser'
import { validar } from '../DataAPI/apiValidar'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function PgSignUp() {





    let history = useHistory();



    const { logado, setlogado } = useContext(MeuContexto)



    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');

    const [artist, setartist] = useState(false)

    const [isArtist, setIsArtist] = useState(false);
    const [errors, setErrors] = useState({});

    const [vusername, setvusername] = useState(true)
    const [vemail, setvemail] = useState(true)
    const [vpassword, setvpassword] = useState(true)
    const [vcpf, setvcpf] = useState(true)

    useEffect(
        () => {

            if (logado) { history.push("/") }
        }, [logado])

        const validateEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
          };
    function _validar() {


        let retorno = true
        let formErrors = {};

        // Validação básica para os campos
        if (!username) formErrors.username = 'Por favor, insira seu nome de usuário.';
        if (!password) formErrors.password = 'Por favor, insira sua senha.';
        if (!email) {
          formErrors.email = 'Por favor, insira seu e-mail.';
        } else if (!validateEmail(email)) {
          formErrors.email = 'Por favor, insira um e-mail válido.';
        }
        if (isArtist && !cpf) {
          formErrors.cpf = 'Por favor, insira seu CPF.';
        }
    
        if (Object.keys(formErrors).length === 0) {
          console.log('Conta criada com sucesso!');
          // Aqui você pode adicionar a lógica de criação de conta

        let retorno = true
        } else {
            retorno = false
          setErrors(formErrors);
        }

        return retorno
    }


    async function _Criarconta(e) {
        e.preventDefault();
        if (_validar()) {
            try {
                const resposta = await data_user.novo({ username, email, password, artist, cpf });
                if (resposta) {
                    const resposta2 = await data_user.conectar({ email, password });
                    if (resposta2) {
                        global.token = resposta2.token

                        setlogado(true)
                        history.push("/")

                    }

                }

            } catch (error) {
                toast.error(

                    'Favor verificar suas informações!'
                );
            }


        }
        else {
            toast.warn(

                'Favor verificar suas informações!'
            );

        }

    }


    return (

        <div className="flex justify-center mt-10 ">
        <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl font-bold text-center mb-6">Criar Conta</h2>
          
          <form onSubmit={_Criarconta}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                Nome de Usuário
              </label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                  errors.username ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                }`}
                placeholder="Digite seu nome de usuário"
              />
              {errors.username && <p className="text-red-500 text-sm mt-1">{errors.username}</p>}
            </div>
  
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                E-mail
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                  errors.email ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                }`}
                placeholder="Digite seu e-mail"
              />
              {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
            </div>
  
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                Senha
              </label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                  errors.password ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                }`}
                placeholder="Digite sua senha"
              />
              {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
            </div>
  
            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={isArtist}
                  onChange={(e) => setIsArtist(e.target.checked)}
                  className="form-checkbox h-5 w-5 text-indigo-600"
                />
                <span className="ml-2 text-gray-700">Sou Pastor</span>
              </label>
            </div>
  
            {isArtist && (
              <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="cpf">
                  CPF
                </label>
                <input
                  type="text"
                  id="cpf"
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 ${
                    errors.cpf ? 'border-red-500 focus:ring-red-500' : 'focus:ring-indigo-950'
                  }`}
                  placeholder="Digite seu CPF"
                />
                {errors.cpf && <p className="text-red-500 text-sm mt-1">{errors.cpf}</p>}
              </div>
            )}
  
            <button
              type="submit"
              className="w-full bg-indigo-950 text-white font-bold py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300"
            >
              Criar Conta
            </button>
          </form>


        {/* Container para o toast */}
        <ToastContainer /> 

        </div>
      </div>
    
    );
}

export default PgSignUp;
import React from 'react';

import './CompModal.css';

import { MdClose } from "react-icons/md";

function Modal({ close, show, children, title }) {

    return (
        <div className={show ? "modal display-block" : "modal display-none"}>

            <section className="modal-main">
                <div className="modal-close">
                    <p>{title} </p>
                    <MdClose onClick={close} color={"#0D0D0D"} size={24} />

                </div>

                {children}

            </section>
        </div>
    );
}

export default Modal;